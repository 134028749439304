import React, {useState,useEffect} from "react";
import i18n from "i18next";
import _ from "lodash";
import { UINames } from "../SummaryViewUINames";
import { Currency } from "../Currency";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import OpenPowerBIReport from "../Shared/OpenPowerBI";



import SisenseDashboardIframe from "../Shared/SisenseDashboardIframe";
import EmbedPowerBI from "../Shared/EmbedPowerBI";
const convTo2DecimalDisplay = (number) => {
  let value = parseFloat(number);
  let ret = value.toFixed(2) % 1 !== 0 ? value.toFixed(2) : parseFloat(value.toFixed(2));
  return ret.toString();
};

function TilesView({ modalFullscreen, allLocation, shouldShowNewDashboardUI, elementDetails, LocationElements, showGroupsTooltip,tempsource,powerbisummary,propsflag,selectedsummaryViewAsset,close,url,selectedAssest }) {

  const dispLayTooltip = (DataSource,idx)=>{
    return (
      <>
      <span
        className="groups-names-tooltip"
        data-tip
        data-for={`header-tooltip-${idx}`}
        onMouseLeave={() => {
          ReactTooltip.hide();
        }}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
        />
      </span>
      <ReactTooltip
        place="right"
        type="dark"
        effect="solid"
        id={`header-tooltip-${idx}`}
      >
        <p>
          Data for <span>{DataSource.toUpperCase()}</span>
        </p>
      </ReactTooltip>
      </>
    )
  }

  const [flag,setFlag] = useState(false);
  const [isPBI,setisPBI] = useState(false);
  const [isOpria,setisOpria] = useState(false);

  useEffect(()=>{
    
    if(url && url.includes('powerbi')){
      setisPBI(true);
    }else if(url && url.includes('opria.accenture.com')){
      setisOpria(true)
    }
  },[url,flag])
  
  

  const handleInsights = () =>{   
    setFlag(true);
  }

  const Close = () =>{
    setFlag(false);    
  }
  
  const renderDashboardView = () => {
    if(shouldShowNewDashboardUI && isOpria){
      return (
        <SisenseDashboardIframe
          data={{
              clientName: '',
              offeringName: '',
              dashboardURL: url,
          }}
          onClose={() => Close()}
          shouldDisableModelView={true}
          modalFullscreen={modalFullscreen}
          selectedAssest={selectedAssest}
        />
      )
    } else if(!shouldShowNewDashboardUI && isOpria && flag){
      return (
        <SisenseDashboardIframe
          data={{
              clientName: '',
              offeringName: '',
              dashboardURL: url,
          }}
          onClose={() => Close()}
        />
      )
    } else if(!shouldShowNewDashboardUI && isPBI && flag){
      return (<OpenPowerBIReport
        flag={flag}
        close={() => Close()}
        source={url}
      />
      )
    } else if(shouldShowNewDashboardUI && isPBI) {
      return (
        <div className="casestudy-modalimg">
          {/*<iframe
              width="100%"
              height= '500px'
              src= {url}
              frameborder="0"
              allowFullScreen="true"
          >
          </iframe>*/}
          <EmbedPowerBI powerbiurl={url} />
        </div>
      )
    } else {
      return (
        <iframe
          width="100%"
          height= '500px'
          src= {url}
          frameborder="0"
          allowFullScreen={true}
        >
        </iframe>
      )
    }
  }
  return (
    <>
    {renderDashboardView()}
    {!shouldShowNewDashboardUI ?    
    <div className="filtered-records">
      {allLocation.filterApply ? (
        <div id="records-accordion" className="records-accordion">
          <div class="card">
            <div id="collapse12" class="collapse show">
              <div class="card-body">
                <div className="records-row flexbox-2">
                  {elementDetails.map((element,idx) => (
                    <div className="record-divider gray-clr">
                      <div className="record-indicate outcome-indicate">
                        {!_.isNil(UINames[element.MetricName]) ? (
                          <div className="record-heading">
                            <h4>{UINames[element.MetricName.trim()]}
                            {element.DataSource &&
                                  dispLayTooltip(element.DataSource,idx)
                                }
                            </h4>
                            {UINames[element.MetricName.trim()].length > 31 ? (
                              <div className="record-heading-tooltip">
                                {UINames[element.MetricName.trim()]}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="record-heading">
                            <h4>{element.MetricName}
                            {element.DataSource &&
                                  dispLayTooltip(element.DataSource,idx)
                                }
                            </h4>
                            {element.MetricName.length > 31 ? (
                              <div className="record-heading-tooltip">{element.MetricName}</div>
                            ) : null}
                          </div>
                        )}
                        {element.Value != null && element.Value !== "NaN" ? (
                          <span className="record-digit">
                            {!_.isNil(Currency[element.Unit])
                              ? Math.abs(element.Value) > 99999999
                                ? convTo2DecimalDisplay(element.Value / 1000000)
                                : convTo2DecimalDisplay(element.Value)
                              : String(element.Unit) === "Hours/Sec"
                              ? Math.abs(element.Value) < 1
                                ? (element.Value * 3600) % 1 === 0
                                  ? convTo2DecimalDisplay(element.Value * 3600)
                                  : convTo2DecimalDisplay(element.Value * 3600)
                                : convTo2DecimalDisplay(element.Value)
                              : convTo2DecimalDisplay(element.Value)}
                            {!_.isNil(Currency[element.Unit]) &&
                            Math.abs(element.Value) <= 99999999 ? (
                              <span className="record-digit-text">{Currency[element.Unit]}</span>
                            ) : String(element.Unit) === "Hours/Sec" ? (
                              Math.abs(element.Value) < 1 ? (
                                <span className="record-digit-text">{i18n.t("Seconds")}</span>
                              ) : (
                                <span className="record-digit-text">{i18n.t("Hours")}</span>
                              )
                            ) : !_.isNil(Currency[element.Unit]) &&
                              Math.abs(element.Value) > 99999999 ? (
                              <span className="record-digit-text">{Currency[element.Unit]} Mn</span>
                            ) : !_.isNil(Currency[element.Unit]) ? (
                              <span className="record-digit-text">{Currency[element.Unit]}</span>
                            ) : (
                              <>
                                <span className="record-digit-text">
                                  {element.Unit.length > 9
                                    ? element.Unit.slice(0, 8) + "..."
                                    : element.Unit}
                                  {element.Unit.length > 9 ? (
                                    <div className="record-heading-tooltip">{element.Unit}</div>
                                  ) : null}
                                </span>
                              </>
                            )}
                          </span>
                        ) : (
                          <span className="record-digit">
                            <span className="record-digit-text">{i18n.t("Data_is_not")}</span>
                          </span>
                        )}
                        <div className="card-overlay">
                                          <div className="d-flex">
                                            <button
                                              type="button"
                                              className="card-chart"
                                              onClick={() => handleInsights()}
                                            >
                                              <span>{i18n.t("INSIGHTS")}</span>
                                            </button>
                                          </div>
                                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div id="records-accordion" className="records-accordion">
            {LocationElements.length !== 0 &&
              LocationElements.map((data, index) => (
                <div class="card">
                  <div class="card-header">
                    <div class="card-link" data-toggle="collapse" href={`#Location${index}`}>
                      {data.LocationName}
                      {showGroupsTooltip && (
                        <>
                          <span
                            className="groups-names-tooltip"
                            data-tip
                            data-for={`group-tooltip-${index}`}
                            onMouseLeave={() => {
                              ReactTooltip.hide();
                            }}
                          >
                            <FontAwesomeIcon
                              /*  data-tip
                                        data-for="gph-tooltip" */

                              icon={faInfoCircle}
                            />
                          </span>
                          <ReactTooltip
                            place="right"
                            type="dark"
                            effect="solid"
                            id={`group-tooltip-${index}`}
                            /* className="kpi-name" */
                          >
                            <p>
                              Group: <span>{data.Groups.map((e) => e.GroupName).join(", ")}</span>
                            </p>
                          </ReactTooltip>
                        </>
                      )}
                    </div>
                  </div>
                  <div id={`Location${index}`} class="collapse show">
                    <div class="card-body">
                      <div className="records-row flexbox-2">
                        {data.elementDetails.map((element,idx) => (
                          <div className="record-divider gray-clr">
                            <div className="record-indicate outcome-indicate">
                              {!_.isNil(UINames[element.MetricName.trim()]) ? (
                                <div className="record-heading">
                                  <h4>{UINames[element.MetricName.trim()]}
                                  {element.DataSource &&
                                  dispLayTooltip(element.DataSource,idx)
                                }
                                  </h4>
                                  {UINames[element.MetricName.trim()].length > 31 ? (
                                    <div className="record-heading-tooltip">
                                      {UINames[element.MetricName.trim()]}
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="record-heading">
                                  <h4>{element.MetricName}
                                  {element.DataSource &&
                                    dispLayTooltip(element.DataSource,idx)
                                }
                                  </h4>
                                  {element.MetricName.length > 31 ? (
                                    <div className="record-heading-tooltip">
                                      {element.MetricName}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                              {element.Value != null && element.Value !== "NaN" ? (
                                <span className="record-digit">
                                  {!_.isNil(Currency[element.Unit])
                                    ? Math.abs(element.Value) > 99999999
                                      ? convTo2DecimalDisplay(element.Value / 1000000)
                                      : convTo2DecimalDisplay(element.Value)
                                    : String(element.Unit) === "Hours/Sec"
                                    ? Math.abs(element.Value) < 1
                                      ? (element.Value * 3600) % 1 === 0
                                        ? convTo2DecimalDisplay(element.Value * 3600)
                                        : convTo2DecimalDisplay(element.Value * 3600)
                                      : convTo2DecimalDisplay(element.Value)
                                    : convTo2DecimalDisplay(element.Value)}
                                  {!_.isNil(Currency[element.Unit]) &&
                                  Math.abs(element.Value) <= 99999999 ? (
                                    <span className="record-digit-text">
                                      {Currency[element.Unit]}
                                    </span>
                                  ) : String(element.Unit) === "Hours/Sec" ? (
                                    Math.abs(element.Value) < 1 ? (
                                      <span className="record-digit-text">{i18n.t("Seconds")}</span>
                                    ) : (
                                      <span className="record-digit-text">{i18n.t("Hours")}</span>
                                    )
                                  ) : !_.isNil(Currency[element.Unit]) &&
                                    Math.abs(element.Value) > 99999999 ? (
                                    <span className="record-digit-text">
                                      {Currency[element.Unit]} Mn
                                    </span>
                                  ) : !_.isNil(Currency[element.Unit]) ? (
                                    <span className="record-digit-text">
                                      {Currency[element.Unit]}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="record-digit-text">
                                        {element.Unit.length > 9
                                          ? element.Unit.slice(0, 8) + "..."
                                          : element.Unit}
                                        {element.Unit.length > 9 ? (
                                          <div className="record-heading-tooltip">
                                            {element.Unit}
                                          </div>
                                        ) : null}
                                      </span>
                                    </>
                                  )}
                                </span>
                              ) : (
                                <span className="record-digit">
                                  <span className="record-digit-text">{i18n.t("Data_is_not")}</span>
                                </span>
                              )}
                              <div className="card-overlay">
                                          <div className="d-flex">
                                            <button
                                              type="button"
                                              className="card-chart"
                                              onClick={() => handleInsights()}
                                            >
                                              <span>{i18n.t("INSIGHTS")}</span>
                                            </button>
                                          </div>
                                        </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>: null }
    </> 
  );
}

export default TilesView;
